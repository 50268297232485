@use '../stylesheets/tailwind.scss';
@use '../stylesheets/button.scss';
@use '../stylesheets/modal.scss';
@use '../stylesheets/form.scss';

body > main { @apply p-5 max-w-xs mx-auto flex flex-col justify-center h-full; }

.employee-list {
  > ul > li { @apply flex items-center gap-2 py-2 px-3 hover:bg-gray-50 rounded; }
  > ul > li > a { @apply flex w-full items-center gap-2; }
  > ul > li > a > aside { @apply grow-0; }
  > ul > li > a > strong { @apply grow; }
}

.avatar-placeholder {
  @apply inline-flex items-center justify-center rounded-full bg-gray-100 text-gray-500 font-bold;
  width: 2.5rem;
  height: 2.5rem;
}