.form {
  display: flex;
  flex-direction: column;
}

.input {
  @apply w-full flex flex-col;

  label { @apply block text-sm font-medium leading-6 text-gray-900; }

  .field_with_errors {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    select {
      @apply block w-full rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500;
    }
  }

  .error-message {
    @apply text-sm text-red-600;
  }
}

.horizontal-form {
  .input {
    @apply grid grid-cols-3 items-center gap-4;

    input[type="text"],
    input[type="number"],
    select {
      @apply col-span-2;
    } 
  }
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="password"],
select {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600;

  &:disabled { @apply bg-gray-100; }
}

.input + .input { @apply mt-2; }