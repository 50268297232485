.button-group {
  @apply isolate inline-flex rounded-md shadow-sm;

  .primary-button,
  .button {
    @apply inline-flex items-center rounded-none -ml-px;

    &:first-child { @apply rounded-l-md rounded-r-none ml-px; }
    &:last-child { @apply rounded-r-md rounded-l-none; }
  }
}

.primary-button {
  @apply inline-block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer disabled:cursor-not-allowed disabled:bg-indigo-400 disabled:text-indigo-100;
}

.button { @apply inline-block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer  disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400; }

.table-button { @apply inline-block rounded-md bg-white px-1.5 py-0.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer; }