.modal-overlay {
  align-items: center;
  background-color: rgb(0 0 0 / .6);
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  padding: 2rem;

  @supports not (inset: 0) {
    & { bottom: 0; left: 0; right: 0; top: 0; }
  }
}

.modal {
  @apply relative transform overflow-hidden rounded-lg bg-white px-6 pb-4 pt-5 text-left shadow-xl transition-all my-8 p-6 max-h-full flex flex-col;

  header { @apply mb-5; }

  section { @apply flex-1 overflow-y-scroll; }

  footer { @apply text-right mt-8; }
  footer > * + * { @apply ml-1; }
}

.modal-small {
  width: 400px;
}

.modal-medium {
  width: 600px;
}